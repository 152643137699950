<template>
  <div>
    <b-card>
      <b-input-group class="mb-3">
        <b-input-group-prepend>
          <b-button variant="primary" @click="list">
            <i class="fa fa-search"></i> 검색
          </b-button>
        </b-input-group-prepend>
        <b-form-input id="search" type="text" placeholder="발란코드, 파트너 주문번호를 입력해주세요" v-model="form.search" @keypress.enter.prevent.stop="list" v-focus></b-form-input>
      </b-input-group>
      <b-row class="mb-3">
        <b-col cols="6" lg="3">
          <div><small>주문결과</small></div>
          <b-form-radio-group class="col-form-label" v-model="form.ok" :options="[
            {text: '전체', value: 'ALL'},
            {text: '성공', value: 1},
            {text: '실패', value: 0}
          ]"></b-form-radio-group>
        </b-col>
        <b-col cols="6" lg="3">
          <div><small>한 번에 가져올 수</small></div>
          <b-form-input id="limit" type="text" placeholder="한 번에 가져올 수" v-model.number="form.limit"></b-form-input>
        </b-col>
      </b-row>
      <b-form-textarea id="goodsNo" :rows="2" v-model="form.order_no" placeholder="주문번호, 일련번호, 파트너 주문번호 등을 입력해주세요"></b-form-textarea>
      <b-button class="m-1" variant="primary" @click="list">검색</b-button>
    </b-card>

    <div class="clearfix">
      <b-button class="m-1 pull-right" variant="success" @click="down('xlsx')">XLSX</b-button>
      <b-button class="m-1 pull-right" variant="success" @click="down('txt')">Text</b-button>
    </div>
    <c-table :table-data="items" :fields="fields" :perPage.sync="perPage" :isBusy="isBusy" :getMoreBusy="getMoreBusy" :hasMore="hasMore" :caption="items.length + ' 개'"
             @btn-clicked="btnAction" @get-more="getMore" @change-per-page="(v)=>{perPage=v}"></c-table>

    <b-modal title="주문결과 다운로드" size="lg" v-model="modal.download" @hide="formDownReason.resolve(false)">
      <b-alert show variant="info">총 {{items.filter(e => e.selected).length}} 건의 주문결과를 다운로드 합니다</b-alert>
      <div><small>사유</small></div>
      <b-form-select v-model="formDownReason.reason" :options="[
          {text: '주문 관련 업무(취소/환불, 반품 등)', value: 'order'},
          {text: '배송 관련 업무', value: 'deliver'},
          {text: '고객 문의 및 고충처리 관련 업무', value: 'customer'},
          {text: '마케팅 관련 업무(CRM, 온사이트 등)', value: 'marketing'},
          {text: '서비스 기획, 개발 관련 업무', value: 'develop'},
          {text: '정산관련 업무', value: 'settle'},
          {text: '기타', value: 'etc'}
        ]">
      </b-form-select>
      <b-form-input v-if="formDownReason.reason === 'etc'" v-model="formDownReason.reasonEtc" placeholder="기타 사유를 입력해 주세요(30자 제한)." maxlength="30"></b-form-input>
      <template v-slot:modal-footer="{}">
        <b-button variant="outline-secondary" @click="formDownReason.resolve(false)">
          Cancel
        </b-button>
        <b-button variant="success" @click="formDownReason.resolve(true)">
          Ok
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import cTable from '@/views/TableBase.vue'
import vSelect from 'vue-select'
import {getJson, postJson} from '@/shared/api'
import * as utils from '@/shared/utils'
import {down} from '@/shared/impexp'
import store from '@/store';
import {DELIVER_BOARD_COLUMNS} from 'balaan_constants'
const {state:S, getters:{R}} = store;

export default {
  name: 'ApiOrderResult',
  title: 'API 주문결과',
  components: {cTable, vSelect},
  data() {
    return {
      form: {
        search: '',
        order_no: '',
        ok: 'ALL',
        limit: 100,
        skip: 0,
      },
      formDownReason: {
        resolve: () => {},
        reason: 'order',
        reasonEtc: '',
      },
      lastForm: {},
      items: [],
      item: {},
      fields: [
        {key:'selected'},
        {key: 'html1', label:'주문번호', class: 'text-center'},
        {key: '_img60', label:'이미지', class: 'text-center'},
        {key: 'html2', label:'상품정보'},
        {key: 'html3', label:'가격', class: 'text-right'},
        {key: 'html4', label:'주문결과<br/>파트너주문번호', class: 'text-center'},
        {key: '_dt', label:'주문일시', class: 'text-center'},
        {key: '_ccdt', label:'취소일시', class: 'text-center'},
        {key:'_actions', label:'상세', style:{width:'55px', textAlign:'center'}, buttons: [{label:'JSON', variant:'light', event:'json_modal'}]},
      ],
      perPage: 20,
      modal: {
        download: false,
      },
      isBusy: false,
      getMoreBusy: false,
      hasMore: false,
      abortController: null,
    }
  },
  async created() {
    this.$utils.getStatus(this.$options.name, this, 'perPage');

    this.list();
  },
  async beforeDestroy() {
    if (this.abortController) this.abortController.abort();
  },
  watch: {
    perPage: function() {
      this.$utils.setStatus(this.$options.name, this, 'perPage');
    }
  },
  methods: {
    async list(more) {
      let form;
      if (more === true) {
        if (this.getMoreBusy) return; // 이전요청을 기다린다
        form = this.lastForm;
        form.skip += form.limit;
        this.getMoreBusy = true;
      } else {
        form = this.form;
        this.lastForm = utils.clone(form);
        this.items.splice(0, this.items.length);
        this.isBusy = true;
      }

      let order_no = this.form.order_no.trim() ? this.form.order_no.trim().split(/\s+/) : [];

      if (this.abortController) this.abortController.abort();
      this.abortController = new AbortController();
      let j;
      try {
        j = await postJson('/order/apiOrderResult', {form:{...form, order_no}}, {signal:this.abortController.signal});
      } catch (e) {
        if (more !== true) this.items = [];
        return;
      }
      this.abortController = null;

      j.list.forEach(ord=>{
        if (!ord.deli) return;
        let e = ord.deli;
        ord.img = e.img_urls[0];
        ord.html1 = `<a href="/#/deliveryBoard/${e.orderno}/${e.order_detailno}" target="_blank"><span class="badge badge-primary">${e.mall}</span><br/>`
          + `${e.orderno}<br/>${e.order_detailno}</a>`;
        ord.html2 = `<a href="/#/shop/${e.shop_id}" target="_blank" class="badge badge-success">${e.shop_id}. ${e.boutique}</a> ` + (e.brand ? `<span class="badge badge-warning">${e.brand}</span>` : '')
          + ` <a href="/#/goods/${e.goodsno}" target="_blank"><span class="badge badge-primary">${e.goodsno}</span></a> <br/>`
          + `${e.goodsnm}<br/><span class="badge badge-secondary">${e.size}</span><span class="badge badge-info">${e.qty}</span>`,
        ord.html3 = `${utils.comma(e.sales_price)}${e.price_table ? `<br/><span style="${e.price_table.supply.finalPriceRoundUp >= e.sales_price ? 'color:red' : ''}">`
          + utils.comma(e.price_table.supply.finalPriceRoundUp) + '</span>' : ''}`;
        ord.html4 = `<span class="badge badge-light">${ord.ok ? 'OK' : 'Error'}</span>`
          + (!ord.results ? '' : '<br/>' + ord.results.map(f=>(!f.ok ? '<del>' : '') + f.rawResult.map(g=>g.req.OrderId || g.req.channelOrderID || g.req.orderNo).join('<br/>') + (!f.ok ? '</del>' : '')).join('<br/>'));
          // + (ord.ok ? '': (ord.err ? `<br/><small>${ord.err.map(e=>e.msg).join('<br/>')}</small>` :
          //   '<br/>' + ord.results.map(r=>r.rawResult.map(raw=>`<span class="badge badge-warning" title="${JSON.stringify(raw.res).replace('"', "'")}">결과보기</span>`).join('&nbsp;')).join('<br/>')));
        ord._ccdt = ord.results ? ord.results.map(r=>r.rawResult.filter(raw=>raw.canceled).map(raw=>raw.cancelTime).join('&nbsp;')).join('<br/>') : '';
      });

      this.hasMore = j.hasMore;
      if (more === true) {
        this.items = this.items.concat(j.list);
        this.getMoreBusy = false;
      } else {
        this.items = j.list;
        this.isBusy = false;
      }
    },
    async down(type) {
      let selectedItems = this.items.filter(e=>e.selected).map(e=>{
        let api_orderno = e.results ? e.results.map(f=>f.rawResult.map(g=>g.req.OrderId || g.req.channelOrderID || g.req.orderNo).join('\n')).join('\n') : '';
        return {result:e.ok ? 'OK' : 'Error', _dt:e._dt, msg:e.err ? e.err.map(e=>e.msg).join('\n') : '', ...e.deli, api_orderno};
      });
      if (!selectedItems.length) return alert('다운받을 주문을 선택해주세요');

      const reasonSelected = await this.checkReason();
      if (!reasonSelected) return;

      let name = DELIVER_BOARD_COLUMNS.filter(e=>e.download).map(e=>e.name);
      let code = DELIVER_BOARD_COLUMNS.filter(e=>e.download).map(e=>e.code);
      name = ['API주문번호','API주문결과','API주문일시','API주문에러'].concat(name);
      code = ['api_orderno','result','_dt','msg'].concat(code);

      down(selectedItems, name, code, `ApiOrderResult_${utils.dt()}`, type);
      this.sendPrivateLog('down', {type}, '다운로드', selectedItems.length);
    },
    showError() {
      console.log(1);
    },
    btnAction(row, event) {
      if (event === 'json_modal') {
        this.$modal.show({title:'JSON 보기', html:'<pre>' + JSON.stringify(row.item, null, 2) + '</pre>'});
      }
    },
    getMore() {
      this.list(true);
    },
    async checkReason() {
      this.modal.download = true;
      return new Promise(resolve => {
        this.formDownReason.resolve = b => {
          if (b && this.formDownReason.reason === 'etc' && this.formDownReason.reasonEtc.trim() === '') {
            return alert('사유를 입력해주세요.');
          }
          resolve(b);
          this.modal.download = false;
        }
      });
    },
    async sendPrivateLog(func, form, type, count) {
      const baseUrl = '/apiOrderResult@';
      const reason = this.formDownReason.reason === 'etc' ? this.formDownReason.reasonEtc : this.formDownReason.reason;
      await this.$api.postJson('/order/privateLog', {func: baseUrl + func, form, type, count, reason});
      this.formDownReason.reason = 'order';
      this.formDownReason.reasonEtc = '';
    }
  }
}
</script>
